var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName, } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { toast } from "react-toastify";
// Customizable Area End
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
export default class EditCustomerProfileController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.apiGetCustomerProfileCallId = "";
        this.apiPutCustomerProfileCallId = "";
        // Customizable Area Start
        this.handleSuccess = (responseJson, errorReponse) => {
            if (responseJson.meta.message && !errorReponse) {
                toast.success(this.props.t(responseJson.meta.message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
                this.setState({
                    isEdit: false,
                });
                this.props.navigation.navigate("customerProfilePage");
            }
        };
        this.handleResponse = (responseJson, errorReponse) => {
            if (responseJson.data && !errorReponse) {
                const data = responseJson.data.attributes;
                this.setState({
                    customerData: data,
                    fullName: data.full_name,
                    email: data.email,
                    phone: data.full_phone_number,
                    profileImage: data.profile_image,
                });
            }
        };
        this.handleisEdit = () => {
            this.setState({
                isEdit: true
            });
        };
        this.handleCancel = () => {
            this.setState({
                isEdit: false
            });
        };
        this.handeleSaveEditDetails = () => {
            if (this.state.fullName === "" || this.state.fullName === null) {
                this.setState({ fullNameError: true });
            }
            if (this.state.fullName === this.state.customerData.full_name &&
                this.state.profileImage === this.state.customerData.profile_image) {
                this.handleCancel();
                toast.error(this.props.t("Nothing has been updated to edit"), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
            }
            else {
                this.editCustomerProfileAPICall();
            }
        };
        this.handleLeftArroeServices = () => {
            this.props.navigation.navigate("customerProfilePage");
        };
        this.handleChangeFullname = (value) => {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ fullName: value });
            }
        };
        this.handleImage = (e) => {
            let imageUrls = '';
            for (const file of e.target.files) {
                const url = URL.createObjectURL(file);
                imageUrls = url;
            }
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                const base64Image = reader.result;
                this.setState({
                    image: { data: base64Image },
                });
            };
            this.setState({
                profileImage: imageUrls,
            });
        };
        this.validateCustomerName = () => {
            if (this.state.fullName === "" || this.state.fullName === null) {
                this.setState({ fullNameError: true });
            }
            else {
                this.setState({ fullNameError: false });
            }
        };
        this.fetchCustomerProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: localStorage.getItem('token'),
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetCustomerProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCustomerProfileEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.editCustomerProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: localStorage.getItem('token'),
                'Content-Type': 'application/json'
            };
            const body = {
                "data": {
                    "attributes": {
                        "full_name": this.state.fullName,
                        "email": this.state.email,
                        "full_phone_number": this.state.phone,
                        "profile_image": this.state.image
                    }
                }
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPutCustomerProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.putCustomerProfileEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            isEdit: false,
            fullName: "",
            email: "",
            phone: "",
            profileImage: "",
            image: undefined,
            fullNameError: false,
            customerData: {}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.apiGetCustomerProfileCallId) {
                    this.handleResponse(responseJson, errorReponse);
                }
                if (apiRequestCallId === this.apiPutCustomerProfileCallId) {
                    this.handleSuccess(responseJson, errorReponse);
                }
            }
            // Customizable Area End
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            // Customizable Area Start
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            if (user != "customer" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.fetchCustomerProfileAPICall();
            }
            // Customizable Area End
        });
    }
}
